import React from "react"

import { Text } from "@blueprintjs/core"

export interface CardHeaderProps {
  onClick?: (e: MouseEvent) => void
  capsHeadline: string
  largeHeadline?: string
}

const CardHeader: React.FC<CardHeaderProps> = ({
  capsHeadline = "",
  largeHeadline = "",
}) => {
  return (
    <div className="cardHeader">
      <Text className="capsHeadline" tagName="span">
        {capsHeadline}
      </Text>
      <Text className="largeHeadline" tagName="h6">
        {largeHeadline}
      </Text>
    </div>
  )
}

export default CardHeader
