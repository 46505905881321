import React from "react"
import { Text, Button } from "@blueprintjs/core"
import useResizeWidth from "@hooks/useResizeWidth"

const texts = [
  {
    title: "Risk Over Time",
    desc: "30 year analysis, broken down yearly",
  },
  {
    title: "Property Comparison",
    desc: "Risks relative to neighboring properties",
  },
  {
    title: "Regional Assessment",
    desc: "How your city and state measure up",
  },
]

const WantTheFullReport = ({ onClick }) => {
  const { width } = useResizeWidth()
  const [open, setOpen] = React.useState(false)

  const isMobile = width <= 619

  React.useEffect(() => {
    setTimeout(() => {
      if (isMobile) setOpen(true)
    }, 7000)
  }, [])

  return (
    <div
      className={`fullReport ${open ? "open" : ""}`}
      style={{ zIndex: isMobile ? 20 : 10 }}
    >
      <div
        className="fullReport-divider first mb-mobile-0"
        onClick={() => {
          if (isMobile) setOpen(true)
        }}
      >
        <Text tagName="p" className="fullReport-text uppercase bold">
          Want the full picture?
        </Text>
      </div>
      <Button className="get-report-button gold-btn" onClick={onClick}>
        <Text tagName="span">Get your FREE full report</Text>
      </Button>
      <div className="fullReport-divider second mb-mobile-25">
        <Text tagName="p" className="fullReport-text width-330">
          For a limited time, we are giving our early users free access to their
          full report.
        </Text>
      </div>
      <div className="fullReport-divider mobile-only">
        <Text tagName="p">
          Includes risk over time, comparison views, and regional assessment.
        </Text>
      </div>
      <div className="fullReport-divider mobile-only">
        <button
          className="btn-link"
          onClick={e => {
            e.stopPropagation()
            setOpen(false)
          }}
        >
          No thanks, not now
        </button>
      </div>

      {texts.map((text, index) => {
        return (
          <div key={index} className="fullReport-divider desktop-only">
            <Text tagName="p" className="fullReport-text bold">
              {text.title}
            </Text>
            <Text tagName="p" className="fullReport-text">
              {text.desc}
            </Text>
          </div>
        )
      })}
    </div>
  )
}
export default WantTheFullReport
