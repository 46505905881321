import React from "react"
import { Text } from "@blueprintjs/core"
import ClientServices from "@services/ClientServices"
import pluralize from "pluralize"
import isEmpty from "lodash/isEmpty"
import { navigate } from "gatsby"

export interface ScoreDescriptionProps {
  ref?: React.Ref<any>
  className?: string
  onClick?: (e: MouseEvent) => void
  scoreCategory: any
  scoreSeverity: any
  scoreData: any
  averages: any
  address: any
}

const getComparisonText = (
  key,
  scoreData,
  averages = {
    state_drought_avg: 0,
    heat_avg: 0,
    fire_avg: 0,
    storm_avg: 0,
  },
  templates = {
    high: data => `${data.diff}% higher than average`,
    low: data => `${Math.abs(data.diff)}% lower than average`,
    avg: data => `about average`,
    times: data => `${data.times} times higher than average`,
  }
) => {
  let diff = 0
  let comparisonText = ""

  diff = Math.round(
    (100 * (scoreData["score"] - averages[key])) / averages[key]
  )

  comparisonText = ""

  if (Number.isFinite(diff)) {
    if (diff > 100) {
      var times = (Math.round(diff / 100) * 100 + 100) / 100
      comparisonText = templates.times({ times })
    } else if (diff > 1) {
      comparisonText = templates.high({ diff })
    } else if (diff < -1) {
      comparisonText = templates.low({ diff })
    } else if (diff <= 1 && diff >= -1) {
      comparisonText = templates.avg({ diff })
    } else {
      comparisonText = `${diff}`
    }
  } else {
    comparisonText = `higher than average`
  }

  return { comparisonText, diff }
}

const getCountyLabel = state => {
  if (state == 'Louisiana'){
    return 'Parish';
  } else return 'County';
}

const getRoundedDroughtIndex = index => {
  var indexPercent = 100 * index
  if (indexPercent >= 1.0) {
    return Math.round(indexPercent)
  } else if (indexPercent < 1 && indexPercent > 0) {
    return Math.round(indexPercent * 10) / 10
  } else if (indexPercent == 0) {
    return 0
  }
}

const getFWILevel = (t) => {
  if (t < 25){
    return "low risk conditions";
  } else if (t < 35){
    return "moderate risk conditions";
  } else if (t < 50){
    return "high risk conditions";
  } else if (t < 60){
    return "very high risk conditions";
  } else if (t >= 60){
    return "extreme risk conditions";
  }
}

const getIntensityDescription = (FLEP8, FLEP4) => {
  if ((100*FLEP8) > 50){
    return "is most likely to be very high intensity (unlikely to be controllable)."
  } else if ((100*FLEP8) > 25){
    return "has a significant chance of being very high intensity (unlikely to be controllable)."
  } else if ((100*FLEP4) > 50){
    return "is most likely to be moderate to high intensity (beyond manual control)."
  } else if ((100*FLEP4) > 10){
    return "has a significant chance of being moderate intensity (beyond manual control)."
  } else {
    return "has a low chance of significant flame heights."
  }
}

const get30YearProbability = (annual_bp) => {
  var bp_30yr_percent = 100*(1 - (1 - annual_bp) **30)
  if (bp_30yr_percent > 1){
    return "about a " + String(Math.round(bp_30yr_percent)) + "%";
  } else if ((bp_30yr_percent < 1) && (bp_30yr_percent >= 0.05)){
    return "about a " + String(Math.round(10*bp_30yr_percent)/10) + "%";
  } else {
    return "a very low"
  }
}

const getFluvialChance = (scoreData) => {
  var d_100 = parseFloat(scoreData["fluvialFlood"]["depth1in100_F"]);
  var d_500 = parseFloat(scoreData["fluvialFlood"]["depth1in500_F"]);
  if (!d_100){ d_100 = 0; }
  if (!d_500){ d_500 = 0; }
  if (d_100 == 0 && d_500 == 0){
    return 0;
  } else if (d_100 == 0 && d_500 > 0){
    return 0.06;
  } else {
    return 0.3;
  }
}

const getRoundedDepth = (expected_depth) => {
  var rounded_desc = ""; // description
  if (expected_depth <= 1) {
    var rounded_depth = expected_depth * 12;

    if (rounded_depth < 2){
      rounded_desc = "about an inch";
    } else if (rounded_depth >= 2 && rounded_depth < 4){
      rounded_desc = "a few inches";
    } else if (rounded_depth >= 4 && rounded_depth < 8){
      rounded_desc = "about half a foot";
    } else {
      rounded_desc = "about a foot";
    }
  } else if (expected_depth > 1) {
      var rounded_depth = Math.floor(expected_depth);
      if (rounded_depth == 1){
        rounded_desc = "over a foot";
      } else {
        rounded_desc = "over " + rounded_depth + " feet";
      }
    }
  return rounded_desc;
}

const getFemaText = scoreData => {
  var risk = ""
  if ("InlandFlood" in scoreData && "risk" in scoreData["InlandFlood"]) {
    risk = scoreData["InlandFlood"]["risk"]
  }

  switch (risk) {
    case "minimal":
      return (
        <>
          <Text tagName="p">
            <strong>FEMA estimate:</strong> area of minimal flood risk
          </Text>
        </>
      )

    case "possible but undetermined":
      return (
        <>
          <Text tagName="p">
            <strong>FEMA estimate:</strong> this area has flood risk, but FEMA
            has not specified your annual risk of a major flood.
          </Text>
        </>
      )
    case "0.2 percent flood zone":
      return (
        <>
          <Text tagName="p">
            <strong>FEMA estimate:</strong> the risk of a major flood is at
            least <strong>6% in 30 years</strong> before accounting for climate
            change.
          </Text>
        </>
      )
    case "1 percent flood zone":
      return (
        <>
          <Text tagName="p">
            <strong>FEMA estimate:</strong> the risk of a major flood is at
            least <strong>26% in 30 years</strong> before accounting for climate
            change.
          </Text>
        </>
      )
    case "1 percent coastal flood zone":
      return (
        <>
          <Text tagName="p">
            <strong>FEMA estimate:</strong> the risk of a major flood is at
            least <strong>26% in 30 years</strong> before accounting for climate
            change.
          </Text>
        </>
      )
    default:
      return (
        <>
          {" "}
          <Text tagName="p"> FEMA has not mapped this area. </Text>
        </>
      )
  }
}

const getFemaDescription = scoreData => {
  return <>{getFemaText(scoreData)}</>
}

const getSeaLevelDescription = scoreData => {
  if (
    Object.keys(scoreData).length === 0 ||
    ("slr_score" in scoreData && scoreData["slr_score"] == 0)
  ) {
    return <></>
  } else if (!("slr_score" in scoreData)){
    return <></>
  }else {
    const futureFlood = Math.round((scoreData["slr_score"] * 365) / 300)
    const floodDayLabel = pluralize("day", futureFlood)

    return (
      <>
        <Text tagName="p">
          You are projected to have about <strong>{futureFlood}</strong>{" "}
          high-tide flooding {floodDayLabel} per year by 2050 with sea level
          rise.
        </Text>
      </>
    )
  }
}

const getStormDescription = scoreData => {
  const baselineRainfall = Math.round((scoreData["baseline_avg_annual"]/25.4)*10)/10
  const futureRainfall = Math.round((scoreData["future_avg_2050_annual"]/25.4)*10)/10

  if (baselineRainfall > futureRainfall)
    return (
      <>
        <Text tagName="p">
        Your yearly rainfall is projected to <strong>decrease</strong>{" "}from about
        {" "}<strong>{baselineRainfall}"{" "}</strong>historically to about <strong>{futureRainfall}"</strong> in 2050.
        </Text>
      </>  
      )
  else if (baselineRainfall < futureRainfall)
    return (
      <>
        <Text tagName="p">
        Your yearly rainfall is projected to <strong>increase</strong>{" "}from about
        {" "}<strong>{baselineRainfall}"</strong>{" "}historically to about <strong>{futureRainfall}"</strong>{" "}in 2050.
        </Text>
      </>  
      )
  else return (
      <>
        <Text tagName="p">
        Your yearly rainfall is projected to remain about <strong>{baselineRainfall}"</strong>{" "} through 2050.
        </Text>
      </>  

    )
}

const getFloodDescription = scoreData => {
  if (
    Object.keys(scoreData).length === 0 ||
    (isEmpty(scoreData["pluvialFlood"]) && isEmpty(scoreData["Storm_surge"]) && isEmpty(scoreData["fluvialFlood"]) && isEmpty(scoreData["InlandFlood"]))
  ) {
    return (
      <>
        <Text tagName="p">
          This property has a <strong>very low</strong> chance of a significant
          storm surge, pluvial, or fluvial flood through 2050.
        </Text>
      </>
    )
  } else if ((isEmpty(scoreData["pluvialFlood"]) && isEmpty(scoreData["Storm_surge"]) && isEmpty(scoreData["fluvialFlood"]) && !isEmpty(scoreData["InlandFlood"]))){
    return (
      <></>
      )
  }else {
    var depth_surge = isEmpty(scoreData["Storm_surge"])
      ? 0
      : scoreData["Storm_surge"]["expected_depth"] // in ft
    var depth_pluvial = isEmpty(scoreData["pluvialFlood"])
      ? 0
      : scoreData["pluvialFlood"]["thirty_year_depth"] / 2.54; //in cm --> converted to in
    var depth_fluvial = isEmpty(scoreData["fluvialFlood"])
      ? 0
      : scoreData["fluvialFlood"]["thirty_year_depth_F"] / 2.54; // in cm --> converted to in
    var chance_surge = isEmpty(scoreData["Storm_surge"])
      ? 0
      : scoreData["Storm_surge"]["chance_30_yr"]
    var chance_pluvial = isEmpty(scoreData["pluvialFlood"])
      ? 0
      : scoreData["pluvialFlood"]["thirty_year_prob"];
    var chance_fluvial = isEmpty(scoreData["fluvialFlood"])
      ? 0
      : getFluvialChance(scoreData);
    var expected_chance =
      Math.round(10 * 100 * (1 - (1 - chance_surge) * (1 - chance_pluvial) * (1 - chance_fluvial))) /
      10;
    if (expected_chance <= 99) {
      //never round to 100% chance
      expected_chance = Math.round(expected_chance)
    }
    var expected_depth = Math.max(depth_pluvial/12, depth_fluvial/12, depth_surge)
    var rounded_depth = getRoundedDepth(expected_depth);

    var article = "a" //certain percentages require "an" before them...
    var floorPercent = Math.floor(expected_chance)
    var an_numbers = [8, 11, 18, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89]
    if (an_numbers.includes(floorPercent)) {
      article = "an"
    }

    return (
      <>
        <Text tagName="p">
          This property has about {article}{" "}
          <strong>
            {expected_chance}
            {"%"}
          </strong>{" "}
          chance of a flood
          {" "}
          <strong>
            {rounded_depth}
          </strong>{" "}
           deep before 2050.
        </Text>
      </>
    )
  }
}

const ScoreDescription: React.FC<ScoreDescriptionProps> = ({
  ref = null,
  className = "",
  onClick = e => console.info("Click"),
  scoreCategory = null,
  scoreSeverity = null,
  scoreData = null,
  averages = null,
  address,
}) => {
  const clientServices = new ClientServices()

  const handleLinkClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation()
    navigate(`/get-premium?address=${address}`)
  }

  let scoreDescription = <></>
  if (
    clientServices.utility.isDefinedWithContent(scoreCategory) &&
    clientServices.utility.isDefinedWithContent(scoreSeverity)
  ) {
    switch (scoreCategory) {
      case "Drought":
        scoreDescription = (
          <>
            <Text tagName="p">
              The percentage of available water supply used by humans determines{" "}
              <em>water stress</em>. An area is at especially high risk when
              water stress is above 40%.
            </Text>
            <Text tagName="p">
              Your water stress now is about{" "}
              <strong>
                {getRoundedDroughtIndex(scoreData["baseline_avg"])}%
              </strong>
              . Your projected water stress in 2050 is about{" "}
              <strong>
                {getRoundedDroughtIndex(scoreData["future_avg_2050"])}%
              </strong>
              .
            </Text>
            <Text tagName="p">
              Your drought risk (<strong>{scoreData["score"]}</strong>) is{" "}
              <strong>
                {
                  getComparisonText("state_drought_avg", scoreData, averages)
                    .comparisonText
                }
              </strong>{" "}
              for people in{" "}
              <span style={{ textTransform: "capitalize" }}>
                {averages["state"]}
              </span>{" "}
              (<strong>{Math.round(averages["state_drought_avg"])}</strong>).
            </Text>
          </>
        )
        break
      case "Heat":
        scoreDescription = (
          <>
            <Text tagName="p">
              A day that reaches above{" "}
              <strong>
                {Math.round(scoreData["threshold"] * (9 / 5) + 32)}ºF
              </strong>{" "}
              is considered hot for this location.
            </Text>
            <Text tagName="p">
              Historically, this location had about{" "}
              <strong>{Math.round(scoreData["baseline_avg_counts"])}</strong> hot days per year. The projection for 2050 is about{" "}
              <strong>{Math.round(scoreData["future_avg_2050_counts"])}</strong> hot days per year.
            </Text>
          </>
        )
        break
      case "Fire":
        if (Object.keys(scoreData).length === 0 || scoreData["score"] === 0 || scoreData["score"] === 1) {
          scoreDescription = (
            <Text tagName="p">This location has a very low risk of burning.</Text>
          )
        } else {
          // get maximum future land burned

          let FWILevel = 

          scoreDescription = (
            <>
              <Text tagName="p">
                About{" "}
                <strong>
                  {Math.round(scoreData["future_avg_2050_counts"])}
                </strong>{" "}
                days in 2050 will have{" "}
                <strong>{getFWILevel(scoreData["baseline_avg_magnitude"])}</strong>{" "}
                for fire spread, compared to{" "}
                <strong>
                  {Math.round(scoreData["baseline_avg_counts"])}
                </strong>{" "}
                historically. A fire here{" "}
                {getIntensityDescription(scoreData["FLEP8"], scoreData["FLEP4"])}{" "} 
              </Text>
              <Text tagName="p">
                This location has{" "}
                <strong>{get30YearProbability(scoreData["BP"])}</strong>{" "}
                chance of burning over the next 30 years.
              </Text>
            </>
          )
        }
        break
      case "Flood":
        scoreDescription = (
          <>
            <Text tagName="p">
              *Location estimate may not match your property.{" "}
              <strong className="strong-link" onClick={handleLinkClick}>
                Instantly get a free report
              </strong>{" "}
              to see a flood map.
            </Text>

            {getFloodDescription(scoreData)}

            {getSeaLevelDescription(scoreData)}

            {getFemaDescription(scoreData)}
          </>
        )
        break
      case "Storm":
        scoreDescription = (
          <>
            {getStormDescription(scoreData)}
            <Text tagName="p">
            An extreme storm for your location is a 48-hour rainfall total greater than{" "}{Math.round((scoreData["threshold"]/25.4)*10)/10}”. 
            </Text>
            <Text tagName="p">
            Historically, about{" "} <strong>{Math.round((scoreData["baseline_avg_totals"]/25.4)*10)/10}”</strong>{" "}
            of rain fell over{" "} <strong>{Math.round(scoreData["baseline_avg_counts"])}</strong> {" "}extreme storms each year.
            </Text>
            <Text tagName="p">
            In 2050, about {" "} <strong>{Math.round((scoreData["future_avg_2050_totals"]/25.4)*10)/10}”</strong> of rain will fall over{" "}
            <strong>{Math.round(scoreData["future_avg_2050_counts"])}</strong> extreme storms each year.
            </Text>
          </>
        )
        break
    }
  }

  return <div className="scoreDescription">{scoreDescription}</div>
}

export default ScoreDescription
