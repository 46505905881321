import React from "react"
import { v4 as uuidv4 } from "uuid"

export interface SvgCircleProps {
  className?: string
  viewbox?: any
  circleId?: any
  renderGradient?: any
  circleProps?: any
}

const SvgCircle: React.FC<SvgCircleProps> = ({
  className = "",
  viewbox = "0 0 50 40",
  circleId = uuidv4(),
  renderGradient = [],
  circleProps = {},
}) => {
  let showGradient = false
  if (renderGradient.length > 0) {
    showGradient = true
  }

  let circleStyles = {}
  if (!showGradient) {
    circleStyles = {
      fill: "#E5E5E5",
    }
  }

  return (
    <svg className={`svgCircle ${className}`} viewBox={viewbox}>
      <defs>
        <linearGradient
          id={`${className}${circleId}`}
          gradientTransform="rotate(0)"
        >
          {renderGradient.length > 0 ? (
            renderGradient.map((stop, i) => {
              return (
                <stop key={i} stopColor={stop.stopColor} offset={stop.offset} />
              )
            })
          ) : (
            <></>
          )}
        </linearGradient>
      </defs>
      <circle
        className="gaugeLine"
        style={{ stroke: `url(#${className}${circleId})`, ...circleStyles }}
        {...circleProps}
      />
    </svg>
  )
}

export default SvgCircle
