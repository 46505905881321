import React, { useState, useEffect } from "react"

import { Text } from "@blueprintjs/core"
import ClientServices from "@services/ClientServices"
import SvgCircle from "../SvgCircle/SvgCircle"

export interface ScoreGaugeProps {
  sortedKeys?: any[]
  sortedScoreContent?: any
  gradientConfig?: any
  viewbox?: any
  defaultProps?: any
  circleProps?: any
  bgCircleProps?: any
  autoGradient?: any
}

const ScoreGaugeDouble: React.FC<ScoreGaugeProps> = ({
  sortedKeys,
  sortedScoreContent,
  viewbox = "0 0 50 40",
  autoGradient = true,
  gradientConfig = [
    { stopColor: "#f44336", offset: "0%" },
    { stopColor: "#ffd900", offset: "50%" },
    { stopColor: "#4caf50", offset: "100%" },
  ],
  defaultProps = {},
  circleProps = {},
  bgCircleProps = {},
}) => {
  const clientServices = new ClientServices()
  const [score, setScore] = useState([0, 0])

  useEffect(() => {
    setScore([
      sortedScoreContent[sortedKeys[sortedKeys.length - 1]]?.scoreData.score,
      sortedScoreContent[sortedKeys[0]]?.scoreData.score,
    ])
  }, [sortedKeys, sortedScoreContent])

  /* setScore([sortedScoreContent[sortedKeys[0]]?.scoreData.score, 71]) */
 

  const dash = (-100 + score[1]) * -1

  const opacity = 1 // must be score of at least 10 to be 100%
  // const gradientCircleOpacity = score < 10 ? 0 + (0.1 * score) : 0;
  const greyCircleOpacity = score[1] < 10 ? 1 - 0.1 * score[1] : 0

  const defaultCircle = {
    cx: "25",
    cy: "20",
    r: "16",
    rotate: "90deg",
    strokeWidth: "10px",
    fill: "none",
  }

  circleProps = {
    ...circleProps,
    strokeDasharray: `0 ${dash} 100 0`,
    opacity,
  }
  bgCircleProps = { ...bgCircleProps, strokeDasharray: `0 0 100 0`, opacity }
  let greyCircleProps = {
    ...bgCircleProps,
    strokeDasharray: `0 0 100 0`,
    opacity: 1,
  }

  const gradients = {
    relativelyLow:[
      { stopColor: "#2EF4AE", offset: "30%" },
      { stopColor: "#00D789", offset: "70%" },
    ],
    significant: [
      { stopColor: "#FFEF61", offset: "30%" },
      { stopColor: "#CEFC6D", offset: "70%" },
    ],
    high: [
      { stopColor: "#FFD84E", offset: "30%" },
      { stopColor: "#FFEF61", offset: "70%" },
    ],
    veryHigh: [
      { stopColor: "#FF7918", offset: "30%" },
      { stopColor: "#FFD84E", offset: "70%" },
    ],
    extreme: [
      { stopColor: "#FF2F2F", offset: "30%" },
      { stopColor: "#FF7918", offset: "70%" },
    ],
    more: [{ stopColor: "#2EF4AE", offset: "70%" }],
  }

  let renderGradient = gradientConfig
  if (autoGradient) {
    const scoreSeverity = clientServices.integrationClient.getScoreSeverity(
      score[1]
    )
    renderGradient = gradients[scoreSeverity.key]
  }
   return (
    <div className="scoreGauge">
      <div className="counterOverlay">
        <div className="col">
          <div className="counter">
            <Text className="count" tagName="span">
              {`${score[0]}-${score[1]}`}
            </Text>
          </div>
          <div className="counter">
            <Text className="totalCount" tagName="span">
              / 100
            </Text>
          </div>
        </div>
      </div>
      <SvgCircle
        className="gaugeSvg"
        viewbox={viewbox}
        renderGradient={renderGradient}
        circleProps={{ ...defaultCircle, ...defaultProps, ...circleProps }}
      />
      <SvgCircle
        className="gaugeBgSvg"
        viewbox={viewbox}
        renderGradient={renderGradient}
        circleProps={{ ...defaultCircle, ...defaultProps, ...bgCircleProps }}
      />
      <SvgCircle
        className="gaugeBgSvg"
        viewbox={viewbox}
        renderGradient={[{ stopColor: "#cccccc", offset: "100%" }]}
        circleProps={{ ...defaultCircle, ...defaultProps, ...greyCircleProps }}
      />
    </div>
  )
}

export default ScoreGaugeDouble
