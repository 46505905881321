import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { navigate } from "gatsby"
import SEO from "@components/SEO"
import Report from "@components/ReportPage"
import "@styles/Components/report.scss"
import LocationWrapper from "../components/LocationWrapper"
import { customRoutes } from "@utils/customRoutes"

const ReportPage = ({ location, data: { seo, allSanityCity } }) => {
  let params = new URLSearchParams(location.search)
  const address = params.get("address")
  const customSeo = {
    title: seo?.seo?.title || "Report",
    ...seo?.seo,
  }

  const rollingHillsRoute =
    "?address=8540%20Rolling%20Hills%20Drive%2C%20Corona%2C%20California%2092883%2C%20United%20States"

  useEffect(() => {
    let temporalParams = new URLSearchParams(location.search)

    if (temporalParams.get("address").split(", ").length <= 2) {
      const place = temporalParams.get("address").split(", ")[0]

      customRoutes.forEach(element => {
        if (element.place === place || element.route === place) {
          navigate(`/${element.route}`)
        }
      })
    }

    if (temporalParams.get("address").split(", ").length === 3) {
      const state = temporalParams.get("address").split(", ")[1]
      const city = temporalParams.get("address").split(", ")[0]

      allSanityCity.nodes.forEach(element => {
        if (element.state?.state === state && element.city === city) {
          navigate(`/${element.state?.slug?.current}/${element.slug?.current}`)
        }
      })
    }
  }, [])

  /* CHECK IF THE ADDRESS TYPED IS rolling hills california AND IF SO REDIRECT TO /california */
  if (location.search === rollingHillsRoute) {
    navigate("/california")
    return <div></div>
  } else {
    return (
      <LocationWrapper>
        <SEO seo={customSeo} />
        <Report address={address} />
      </LocationWrapper>
    )
  }
}

export default ReportPage

export const query = graphql`
  query ReportPage {
    seo: sanityPagesSeo(slug: { current: { eq: "/report" } }) {
      seo {
        title
        keywords
        description
      }
    }

    allSanityCity {
      nodes {
        city
        oldUrl
        slug {
          current
        }
        state {
          state
          slug {
            current
          }
        }
      }
    }
  }
`
