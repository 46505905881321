import React, { useState } from "react"
import {
  Text,
  Card,
  Button,
  Popover,
  Position,
  Tooltip,
} from "@blueprintjs/core"
import { Link } from "gatsby"
import Pin from "@images/img-min/pin.svg"
import TwitterIcon from "@images/img-min/icon-social-twitter.svg"
import FacebookIcon from "@images/img-min/icon-social-facebook.svg"
import LinkedIcon from "@images/img-min/icon-social-link.svg"
import ArrowDown from "@images/img-min/arrowDown.svg"
import CardHeader from "../../ui/CardHeader/CardHeader"
import ScoreGauge from "../../ui/ScoreGauge/ScoreGauge"
import ScoreGaugeDouble from "../../ui/ScoreGaugeDouble/ScoreGaugeDouble"
import ClientServices from "@services/ClientServices"
import ClimateCheckLogo from "../../ui/ClimateCheckLogo/ClimateCheckLogo"
import MiniCard from "../../ui/MiniCard/MiniCard"
import trimEnd from "lodash/trimEnd"
import isEmpty from "lodash/isEmpty"
import sortBy from "lodash/sortBy"
import { navigate } from "gatsby"
import CorporativeFooter from "@components/global/CorporativeFooter"
import fetch from "node-fetch"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { getPlacePredictions } from "@services/MapBox"
import WantTheFullReport from "./WantTheFullReport"
import { constants } from "../../constants/constants"
import randomInt from "random-int"
import LocationContext from "../../locationContext/locationContext"
import CorporativeHeader from "@components/global/CorporativeHeader"
import InputSearch from "@components/global/InputSearch"
import Footer from "@components/global/Footer"

enum ReportModes {
  SUMMARY = "summary",
  DETAILED = "detailed",
}

const ReportPage = ({ address }) => {
  if (!address) return <div>loading</div>

  const { lon, lat } = React.useContext(LocationContext)

  const scoreCategories = ["Drought", "Heat", "Fire", "Flood", "Storm"],
    clientServices = new ClientServices(),
    [{ sortedScoreContent, average, sortedKeys }, setScoreContent] = useState({
      sortedScoreContent: {},
      sortedKeys: [],
      average: 0,
    }),
    [reportMode, setReportMode] = React.useState(ReportModes.DETAILED),
    [ctrlAddress, setCtrlAddress] = React.useState(false),
    [loaded, setLoaded] = React.useState(false),
    [loading, setLoading] = React.useState(false),
    [scores, setScores] = React.useState(undefined),
    [search, setSearch] = React.useState(address),
    scoreAverage = average / scoreCategories.length,
    summaryScore = Math.round(scoreAverage),
    summaryScoreSeverity = clientServices.integrationClient.getScoreSeverity(
      summaryScore
    ),
    displayAddress = clientServices.strings.getDisplayAddress(address),
    sortScoreCategories = (reportData: { [x: string]: any }) => {
      let sortedScoreContent: any[] = [],
        sortedKeys: any[] = [],
        average = 0

      scoreCategories.forEach(scoreCategory => {
        let scoreData = {
          score: 0,
        } as any

        // redirect heat and fire to new DB collections:
        var categoryCollectionName = scoreCategory
        if (scoreCategory == "Heat") {
          categoryCollectionName = "HeatMay22"
        } else if (scoreCategory == "Fire") {
          categoryCollectionName = "FireMay22"
        }

        if (
          clientServices.utility.isDefinedWithContent(reportData) &&
          clientServices.utility.isDefinedWithContent(
            reportData[categoryCollectionName]
          )
        ) {
          scoreData = isEmpty(reportData[categoryCollectionName])
            ? { score: 1 }
            : reportData[categoryCollectionName]

          if (scoreCategory == "Flood") {
            // calculate cumulative score, and store quantities needed for description
            let slrScore = 0,
              femaScore = 0,
              pluvialScore = 0,
              fluvialScore = 0,
              stormSurgeScore = 0
            scoreData.score = 0
            if (reportData["Flood"].hasOwnProperty("score_2050")) {
              scoreData["slr_score"] = reportData["Flood"]["score_2050"]
              slrScore = reportData["Flood"]["score_2050"]
            } else scoreData["slr_score"] = 0
            if (!isEmpty(reportData["InlandFlood"])) {
              // FEMA
              const fema_risk_100 = [
                "1 percent coastal flood zone",
                "1 percent flood zone",
              ]
              if (fema_risk_100.includes(reportData["InlandFlood"]["risk"])) {
                femaScore = 35
              } else if (
                reportData["InlandFlood"]["risk"] == "0.2 percent flood zone"
              ) {
                femaScore = 25
              }
              scoreData["InlandFlood"] = reportData["InlandFlood"]
            }
            if (!isEmpty(reportData["Caddies"])) {
              pluvialScore = reportData["Caddies"]["score"]
              scoreData["pluvialFlood"] = reportData["Caddies"]
            }
            if (!isEmpty(reportData["Lisflood_rf"])) {
              if (reportData["Lisflood_rf"].hasOwnProperty("score_F")) {
                fluvialScore = reportData["Lisflood_rf"]["score_F"]
                scoreData["fluvialFlood"] = reportData["Lisflood_rf"]
              } else {
                fluvialScore = 0
              }
            }
            if (!isEmpty(reportData["Storm_surge"])) {
              stormSurgeScore = reportData["Storm_surge"]["score"]
              scoreData["Storm_surge"] = reportData["Storm_surge"]
            }
            //compute cumulative score
            let score_cum = 0
            const scores = [
              slrScore,
              pluvialScore,
              fluvialScore,
              stormSurgeScore,
            ]

            scores.forEach(function(value) {
              let remainder = 100 - score_cum
              score_cum += (value * remainder) / 100
            })

            scoreData.score = Math.round(score_cum)
            if (scoreData.score == 0) {
              scoreData.score = 1
            }
            if (scoreData.score < femaScore && femaScore > 0) {
              scoreData.score = femaScore
            }
          }
        }

        //Round scores, fixes backend issue where score is 29.123123 for example
        if ("score_2050" in scoreData && !("score" in scoreData)) {
          scoreData["score"] = scoreData["score_2050"]
        }
        scoreData.score = Math.round(scoreData.score)
        if (scoreData.score == 0) {
          scoreData.score = 1
        }

        var scoreSeverity = clientServices.integrationClient.getScoreSeverity(
            scoreData.score
          ) as any,
          contentPosition = parseInt(
            (scoreData.score + 10).toString() + randomInt(100, 1000)
          )

        if (scoreCategory == "Flood") {
          scoreSeverity.label = scoreSeverity.label + "*"
        }

        average += scoreData.score

        sortedScoreContent[contentPosition] = {
          scoreCategory,
          scoreData,
          scoreSeverity,
        }

        sortedKeys[sortedKeys.length] = contentPosition
      })

      sortedKeys = sortBy(sortedKeys).reverse() as any
      setScoreContent({
        sortedScoreContent,
        sortedKeys,
        average,
      })
    },
    dynamicLookupFetch = async (search: string) => {
      await getPlacePredictions(search, lon, lat)
        .then(mapResults => {
          mapResults.forEach(
            result =>
              (result.place_name = trimEnd(
                trimEnd(result.place_name, " USA"),
                ","
              ))
          )
          return mapResults.length > 0 ? mapResults[0] : undefined
        })
        .then(
          async data =>
            await fetch(
              `${constants.API_URL}/lookup/${data.center[1]}/${data.center[0]}`
            )
        )
        .then(async (res: { json: () => any }) => {
          let response = await res.json()

          if (!response.Error) {
            setScores(response)
            sortScoreCategories(response)
            setLoading(false)
          }
        })
        .catch(() => {
          clientServices.logs.event("Invalid address submitted:", { search })
          window.location.href = "/"
        })
    }

  if (!loading && !loaded && !scores && search) {
    setLoading(true)
    dynamicLookupFetch(decodeURIComponent(search))
  } else if (!scores && !search) {
    if (typeof window !== "undefined") {
      location.href = "/"
    }
  }

  React.useEffect(() => {
    if (!loading && scores) {
      sortScoreCategories(scores)
      setLoaded(true)
    }
  }, [])

  const handleSendAdress = (search, newScores) => {
    setSearch(search)
    setScores(newScores)
    sortScoreCategories(newScores)
    setCtrlAddress(false)
    navigate(`/report?address=${search}`)
  }

  const handleGoNextClick = () => {
    navigate(`/get-premium?address=${search}`)
  }

  return (
    <>
      <main className="reportView">
        <CorporativeHeader />
        <div className="report-title">
          <header className="reportHeader">
            <div className="icons-social">
              <a
                href="https://twitter.com/MyClimateCheck"
                target="_blank"
                rel="noopener"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.facebook.com/myclimatecheck/"
                target="_blank"
                rel="noopener"
              >
                <FacebookIcon />
              </a>
              <Popover
                content={<span className="copy-link-popover">Link copied</span>}
                position={Position.BOTTOM}
              >
                <Tooltip
                  content="Click to copy link"
                  position={Position.BOTTOM}
                >
                  <CopyToClipboard
                    text={typeof window !== "undefined" && window.location.href}
                  >
                    <span>
                      <LinkedIcon className="copy-link" />
                    </span>
                  </CopyToClipboard>
                </Tooltip>
              </Popover>
            </div>
          </header>
          <div className="headerCenter">
            <Text className="reportTitle" tagName="h1">
              Your climate change risk snapshot
            </Text>
            <div className="addressCtrls">
              {ctrlAddress ? (
                <div className="updateAddress">
                  <InputSearch />
                </div>
              ) : (
                <Button
                  className="currentAddress"
                  onClick={() => setCtrlAddress(true)}
                >
                  <Pin />
                  <Text tagName="span">
                    {displayAddress !== ""
                      ? displayAddress
                      : "Click to enter your address"}
                  </Text>
                </Button>
              )}
            </div>
          </div>
        </div>

        {address && scores && (
          <>
            <section className="reportBody">
              <Card
                className={`gaugeCard ${
                  reportMode == ReportModes.DETAILED
                    ? "expandCard"
                    : "simpleCard"
                }`}
              >
                {reportMode === ReportModes.SUMMARY && (
                  <div className="summaryMode">
                    <CardHeader
                      capsHeadline="Your Score"
                      largeHeadline={`risk ${
                        summaryScore ? summaryScoreSeverity.label : ""
                      }`}
                    />
                    <ScoreGauge
                      score={summaryScore}
                      autoGradient={true}
                      defaultProps={{ cx: "23", strokeWidth: "8px" }}
                    />
                  </div>
                )}

                {reportMode === ReportModes.DETAILED && (
                  <div className="detailedMode">
                    <CardHeader capsHeadline="" largeHeadline="" />
                    <section className="cardBody">
                      <div className="summaryArea">
                        <CardHeader
                          capsHeadline=""
                          largeHeadline="Risk Range"
                        />
                        <div className="gaugeWrapper">
                          <ScoreGaugeDouble
                            sortedKeys={sortedKeys}
                            sortedScoreContent={sortedScoreContent}
                            autoGradient={true}
                            defaultProps={{ cx: "23", strokeWidth: "8px" }}
                          />
                        </div>
                      </div>
                      <div className="detailArea grid col3">
                        {sortedKeys.map((key: any, i) => {
                          const scoreContent = sortedScoreContent[key],
                            reportAverages = scores ? scores.Averages : null
                          return (
                            <MiniCard
                              key={`miniCard${i}`}
                              scoreCategory={scoreContent.scoreCategory}
                              scoreSeverity={scoreContent.scoreSeverity}
                              scoreData={scoreContent.scoreData}
                              averages={reportAverages}
                              address={address}
                            />
                          )
                        })}
                        <Card className="infoCard">
                          <Text tagName="span">About This Rating</Text>
                          <Text tagName="p">
                            ClimateCheck® ratings reflect hazard risk at a
                            property relative to the rest of the contiguous
                            United States. Ratings are based on projected 2050
                            risk and the change from historical risk.
                          </Text>
                          <Text tagName="p">
                            A rating of 1 represents the lowest risk; 100 is the
                            highest.{" "}
                            <Link to="/our-methodologies">
                              See our methodology for details.
                            </Link>
                          </Text>
                        </Card>
                      </div>
                    </section>
                  </div>
                )}
              </Card>
            </section>
            <div className="reportRequest">
              {reportMode === ReportModes.SUMMARY && (
                <Button
                  className="goNext"
                  onClick={() => {
                    clientServices.logs.ga("event", {
                      category: "Call-to-Action",
                      action: "Find out why",
                      value: Date.now().toString(),
                    })
                    setReportMode(ReportModes.DETAILED)
                  }}
                >
                  <Text tagName="span">Find out why</Text>
                  <div className="nextIcon">
                    <ArrowDown />
                  </div>
                </Button>
              )}
              <WantTheFullReport onClick={handleGoNextClick} />
            </div>
          </>
        )}
      </main>
      <Footer />
    </>
  )
}

export default ReportPage
